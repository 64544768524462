<template>
  <div class="container mx-auto text-cream text-center">
    <div class="py-8 my-24">
      <p class="italic text-xl md:text-2xl">'.. P&D constantly excel.'</p>
      <div class="mt-2 font-sans-dark flex justify-center items-center ">
        <p class="text-xs md:text-base border-b border-gold">Ken Sherrock, Senior Site Manager at Fortem Solutions</p>
      </div>
      <div class="w-full flex justify-center items-center h-96 flex-wrap mt-12 md:mt-auto px-4 md:px--0">
        <div class="w-full lg:w-1/2 text-sm md:text-xl xl:text-2xl text-center lg:text-right">
          <slot></slot>
        </div>
        <div class="w-1/2">
          <router-link to="/contact">
            <button class="py-2 px-8 rounded-xl text-cream border-cream border border-b-4 text-base md:text-2xl shadow font-sans-dark hover:bg-cream hover:text-primary">Let's Discuss Your Project</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InnerTest"
}
</script>

<style scoped>

</style>
