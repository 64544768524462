<template>
  <div>
    <div class="fixed top-0 left-0 w-full h-screen bg-main bg-left lg:bg-center bg-cover bg-no-repeat z-0" :style="`top:${$store.state.bgTop}px`"></div>
    <div class="relative z-10">
      <div class="w-full flex flex-col justify-center items-center h-screen">
        <div class="flex flex-col  items-center justify-center w-full lg:w-1/2 px-4 ">
          <div ref="logo" class="w-40 lg:w-64 xl:w-96">
            <img src="../assets/1x/Logo.jpeg" alt="">
          </div>
          <div class="px-2 py-4 w-full md:w-1/2 mt-8 md:mt-4 bg-white bg-opacity-80 border border-primary filter drop-shadow shadow">
            <p class="text-2xl md:text-2xl tracking-wide leading-10 text-center font-sans">Bespoke encasement solutions designed &amp; installed with superior service</p>
          </div>
        </div>
      </div>



    </div>

    <div class="bg-white relative z-10 pt-20">

      <div class="container mx-auto p-4 mb-20">
        <h2 class="text-2xl underline">Privacy Policy</h2>
        <h3 class="text-lg font-sans-dark">Our Contact Details</h3>
        <p>Dave Withers - office@panddworcs.co.uk</p>
        <h3 class="text-lg font-sans-dark">Our Bases For Processing</h3>
        <p>We have a <span class="underline">contractual obligation</span> to process your data.</p>  <p>The data stored on this basis includes names, telephone numbers and email addresses.</p>
        <p>An example of this use is when you contact us for more information about our work.  There is processing necessary before we undertake any work, for example the provision of estimates.</p>
        <p>The length of time that we keep this data varies because of the nature of business.  Contact us for more information.</p>

        <p>We have a <span class="underline">legal obligation</span> to process some data.</p>  <p>The data stored on this basis includes names, telephone numbers and email addresses.</p>
        <p>An example of this is our financial records.  We are bound by legislation to maintain these records.  We store information on this basis for a period of 7 years.</p>

        <h3 class="text-lg font-sans-dark">Sub-processing</h3>
        <p><span class="underline">We do not share your information with anyone else for their use.</span>  To operate our business we use cloud services, including our email provider.  We take steps
        to ensure that all of your sub-processors meet a minimum standard and can assure you that your data is safe.</p>

        <h3 class="text-lg font-sans-dark">Your rights</h3>
        <ul>
          <li>        Your right of access - You have the right to ask us for copies of your personal information.</li>
          <li>        Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
          </li>
          <li>        Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.
          </li>
          <li>        Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.
          </li>
          <li>        Your right to object to processing - You have the right to object to the processing of your personal information in certain circumstances.
          </li>
          <li>        Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.
          </li>
        </ul>
        <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>

        <h3 class="text-lg font-sans-dark">How to complain</h3>
        <p>If you have any concerns about our use of your personal information, you can make a complaint to us.</p>
        <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>



      </div>



      <div id="testimonial" >
        <div>
          <img src="../assets/1x/test-logo.png" alt="A logo from one of our clients" class="mb-12 xl:mb-0">
        </div>
        <div class="px-4 md:px-0 md:w-3/4 md:ml-12 xl:px-12 text-base md:text-lg">
          <p>P&D started working on our sprinkler contract last year, The main work Dave and his team are carrying out for us is the boxing of sprinkler pipe work.
          </p>
          <p>Both myself and my team have set a high expectation regarding the quality of carpentry works on our projects of which P&D constantly excel.
          </p>
          <p>P&Ds Team are polite with the Tenants and maintain a professional approach in their daily work stream, working closely with our TLOs and Site Manager to complete the program and maintain a high level of health and safety.</p>
          <div class="mt-4 font-bold">Ken Sherrock, Senior Site Manager at Fortem Solutions </div>
        </div>
      </div>

      <div id="accreditation" class="flex justify-around items-center w-3/4 lg:container mx-auto pb-48">
        <img src="../assets/1x/cline-gold.png" alt="P&D Constructionline Gold Logo" class="w-1/3 lg:w-auto">
        <img src="../assets/1x/chas.png" alt="P&D CHAS Accredited" class="w-1/3 lg:w-auto">
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'Privacy',
  title: 'P&D Keeps Your Data Safe',
  meta: 'We take your privacy seriously, take a look at how we operate.',

}
</script>

<style scoped>

.main-btn{
  @apply text-xl lg:text-2xl text-white px-8 py-2 rounded-xl shadow-xl border-white border bg-btn
}



#cc-mob-text p{
  @apply text-base md:text-lg pl-10 md:pl-16 pr-10 md:pr-32 mb-2 text-justify
}

#rad-image{
  @apply w-full md:w-2/3 absolute bottom-16 md:bottom-20 lg:bottom-60 xl:bottom-8 2xl:bottom-0 right-4 md:right-8 xl:right-0
}

#rad-text{
  @apply pt-80 lg:pt-96 xl:pt-48 2xl:pt-32
}

#rad-text h2{
  @apply mt-16 lg:mt-12 xl:mt-16 ml-44 text-4xl 2xl:text-5xl font-bold
}

#rad-text p{
  @apply leading-7
  text-xl xl:leading-8
  2xl:text-2xl 2xl:leading-9
  pl-44 mt-3 2xl:mt-4
  text-gray-500
}

#rad-mob-text{
  @apply lg:hidden container mx-auto pb-20 -mt-4
}


#rad-mob-text p{
  @apply text-base md:text-lg pl-10 md:pl-16 pr-10 md:pr-32 mb-2 text-justify
}



h2{
  @apply text-gray-700
}

#service-text{
  @apply pt-20 lg:pt-108 xl:pt-56 2xl:pt-48

}

#service-text h2{
  @apply mt-16 lg:mt-0 xl:mt-16 ml-36 2xl:ml-44 text-4xl 2xl:text-5xl font-bold
}

#service-text p{
  @apply leading-7
  text-xl xl:leading-8
  2xl:text-2xl 2xl:leading-9
  ml-36 2xl:pl-10 mt-2 2xl:mt-4
  text-gray-500
}
#service-image{
  @apply absolute bottom-20 md:bottom-32 lg:bottom-80 xl:bottom-32 2xl:bottom-4 w-full md:w-3/4 md:right-40
}

#service-mob-text{
  @apply lg:hidden container mx-auto pb-20 -mt-8
}


#service-mob-text p{
  @apply text-base md:text-lg pl-10 md:pl-16 pr-10 md:pr-32 mb-2 text-justify
}

#testimonial{
  @apply flex flex-col xl:flex-row container mx-auto lg:pt-52 pb-20 lg:pb-40  items-center justify-center text-xl leading-8
}

#testimonial p{
  @apply mt-2 italic
}

#intro span {
  @apply transition-all duration-700
}

h3{
  @apply mt-4
}

</style>
