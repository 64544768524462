<template>
  <div class="flex justify-around items-center my-24 xl:my-96 container mx-auto flex-wrap">
    <img :src="threeImages[0]" alt=""  class="border border-primary shadow w-40 lg:w-auto">
    <div class="w-24 hidden xl:block">
      <logo-light-bg></logo-light-bg>
    </div>
    <img :src="threeImages[1]" alt="" class="border border-primary shadow w-40 lg:w-auto">
    <img src="../assets/1x/chas.png"  alt="P&D CHAS Accreditation" class="w-32 mt-8 hidden xl:block">
    <img :src="threeImages[2]" alt=""  class="border border-primary shadow w-40 lg:w-auto mt-4 md:mt-auto">
    <div class="w-full text-center mt-8 text-xl">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LogoLightBg from "@/components/LogoLightBg";
export default {
  name: "Work",
  components: {LogoLightBg},
  props: ['threeImages']
}
</script>

<style scoped>

</style>
