<template>
  <div>
    <splash :img="img" :bg-class="'bg-serv'">
      Sprinkler & Service Casing
    </splash>

    <div class="w-full mt-24">
      <first-text :img="textImage">
        <template #title>Bespoke Encasement Solutions Designed & Installed With Superior Service</template>
        <template #bodyText>
          <p>Fire sprinkler boxing provides a practical, effective and time efficient encasement solution for retro-fitted fire sprinkler systems.</p>
          <p className="mt-4">Manufactured in the UK, and available on short lead time this pre-formed solution is cut, drilled and professionally finished on site.</p>
          <p className="mt-4">Access for safety checks, routine inspection and maintenance can be carried out easily by refitting without damage.</p>
          <p className="mt-4 mb-8">A range of sizes are available, as well as bespoke solutions.  P&D are preferred installers for sprinkler boxing.</p>
        </template>
      </first-text>


      <work :three-images="threeImages">
        <span class="font-sans-dark">Fortem Solutions</span> Retro-fitted Sprinkler System
      </work>

    </div>

    <div class="w-full overflow-hidden hidden md:block">
      <mySlider :three-images="sliderImages">
        <ul>
          <li>Sprinkler Boxing</li>
          <li className="italic ml-2 text-lg">Fast turnaround</li>
          <li className="italic ml-2 text-lg">Retro-fit solution</li>
          <li className="italic ml-2 text-lg">Pre-formed and finished on site</li>
          <li className="mt-2">Boiler Pipe Casing</li>
          <li className="italic ml-2 text-lg">Conceal boiler pipework, valves, filters and flues</li>
          <li className="italic ml-2 text-lg">Easy access for safety checks</li>
          <li className="mt-2">Pipe Boxing</li>
          <li className="italic ml-2 text-lg">Unobtrusive solutions</li>
          <li className="italic ml-2 text-lg">Range of styles and colours available</li>
          <li className="font-sans-dark mt-4">Design - Project Management - Installation</li>
        </ul>      </mySlider>
    </div>

    <div class="w-full flex flex-col justify-center items-center mt-48 bg-primary border-t border-gold filter drop-shadow-lg">
      <inner-test>
        <p className="mb-2">Sprinkler Boxing & Service Encasement Solutions <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p class="mb-2">Complete Design Service <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p class="mb-2">Professional Installation <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p>CHAS & ConstructionLine Accredited <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
      </inner-test>
    </div>
  </div>
</template>

<script>
import Splash from "@/components/Splash";
import FirstText from "@/components/FirstText";
import textImage from '../assets/1x/service-gray.webp'
import Work from "@/components/Work";
import S1 from '../assets/1x/s1.webp'
import S2 from '../assets/1x/s2.webp'
import S3 from '../assets/1x/s3.webp'
import Serv1 from '../assets/1x/serv-1.webp'
import Serv2 from '../assets/1x/serv-2.webp'
import Serv3 from '../assets/1x/serv-3.webp'
import mySlider from "@/components/Slider";
import InnerTest from "@/components/InnerTest";
export default {
  name: "Service",
  title: "Sprinkler Boxing & Service Casing",
  meta: 'We install sprinkler and service boxing to protect your fire risk mitigation whether retro-fitted or design built.  P&D are the nationwide encasement specialists.',
  components:{InnerTest, mySlider, Work, FirstText, Splash},
  data(){
    return{
      textImage: textImage,
      threeImages: [
        S1, S2, S3
      ],
      sliderImages: [
        Serv1, Serv2, Serv3
      ]
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
