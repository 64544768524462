<template>
  <div class="h-screen flex justify-center items-center">
    <div class="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-center items-center  p-4 rounded justify-center bg-white bg-opacity-70 w-full">
      <div ref="logo" class="w-60 lg:w-80 xl:w-96 xl:mr-8 md:mr-4 lg:mr-0">
        <img src="../assets/1x/Logo.jpeg" >
        <div class="text-2xl lg:text-4xl font-sans-dark mt-4 lg:mt-8 text-center">
          <slot></slot>
        </div>
      </div>
      <div class="w-80 md:w-72 lg:w-196 bg-white bg-center bg-no-repeat h-80 md:h-60 lg:h-80 xl:h-120 bg-contain xl:px-60 lg:mt-20" :class="bgClass" rel="prefetch">&nbsp;</div>


    </div>



  </div>
</template>

<script>
export default {
  name: "Splash",
  props:['img', 'bgClass']
}
</script>

<style scoped>

</style>
