<template>
  <div class="container mx-auto flex justify-center items-center rounded px-6">
    <div class="w-1/2 relative h-120 flex justify-center items-center" id="anim" v-if="threeImages.length === 3">
      <div class="w-60">
        <logo-light-bg></logo-light-bg>
      </div>
      <img :src="threeImages[0]" alt="" class="filter drop-shadow-xl">
      <img :src="threeImages[1]" alt="" class="filter drop-shadow-xl">
      <img :src="threeImages[2]" alt="" class="filter drop-shadow-xl">

    </div>
    <div class="w-1/2 h-96 px-8 flex items-center justify-center" v-else>
      <img src="../assets/1x/three-cols.webp" alt="P&D Column Casing Image" class="float-right">
    </div>
    <div class="w-1/2 h-96 lg:h-120 relative flex-grow ">
      <div class="absolute top-8 w-full z-20 ">
        <div class="flex flex-col justify-center items-center text-base lg:text-xl xl:text-2xl font-sans py-0 lg:py-20">
          <div class="pl-8 lg:pl-0">
            <slot></slot>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoLightBg from "@/components/LogoLightBg";
import anime from "animejs";
export default {
  name: "Slider",
  components: {LogoLightBg},
  props: ['threeImages'],
  mounted() {
    anime({
      targets: '#anim img',
      keyframes:[
        {
          translateY:[-1000,0],
          duration:800
        }, {
          duration:5000
        },
        {
          translateY:[0,1000],
          duration:400
        },
      ],
      opacity:[0,100],
      easing:'easeInOutBack',
      delay: anime.stagger(6000),
      loop: true,



    });
  }
}
</script>

<style scoped>
#anim img{
  @apply top-24 lg:top-0 h-96 lg:h-120 absolute opacity-0 z-10 py-8
}
</style>
