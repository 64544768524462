<template>
  <div>
   <splash :img="img" :bg-class="'bg-rad'">
     Radiator &amp; Pipe Solutions
   </splash>

    <div class="w-full mt-24">
      <first-text :img="textImage">
        <template #title>Bespoke Encasement Solutions Designed & Installed With Superior Service</template>
        <template #bodyText>
          <p><span class="font-sans-dark">Low Surface Temperature (LST)</span> Radiator Guards provide protection against burn injury and mitigate the risk to users in care homes, sheltered housing, schools, hospitals and many other settings.</p>
          <p class="mt-4">We only source quality, tested products and our choice of radiator guard is designed to maintain thermal efficiency and has been developed in conjunction with NHS trusts.</p>
          <p class="mt-4">Anti-bacterial coatings, lockable drop-down panels and anti-ligature designs are just some of the practical solutions we can install.</p>
          <p class="mt-4">Strong, durable, safe and installed according to the manufacturers latest guidance and standards.</p>
        </template>
      </first-text>


      <work :three-images="threeImages">
        <span class="font-sans-dark">Slough Ice Arena</span> Heating pipe and radiator guard installation
      </work>

    </div>

    <div class="w-full overflow-hidden hidden md:block">
      <mySlider :three-images="sliderImages">
        <ul class="mt-8" >
          <li>Designed For Safety</li>
          <li class="italic ml-2 text-base lg:text-base lg:text-lg">Bullnose corners protect against impact risk</li>
          <li class="italic ml-2 text-base lg:text-lg">Anti-ligature mesh removes ligature points</li>
          <li class="mt-2">Hygiene first</li>
          <li class="italic ml-2 text-base lg:text-lg">Anti-bacterial coatings</li>
          <li class="italic ml-2 text-base lg:text-lg">Drop down panels for cleaning</li>
          <li class="mt-2">Your design</li>
          <li class="italic ml-2 text-base lg:text-lg">RAL colours available</li>
          <li class="mt-2">Guaranteed Temperature Control</li>
          <li class="italic ml-2 text-base lg:text-lg">Internal baffles enable compliance with regulations</li>

          <li class="font-sans-dark mt-4">Design - Project Management - Installation</li>
        </ul>
      </mySlider>
    </div>

    <div class="w-full flex flex-col justify-center items-center mt-48 bg-primary border-t  border-gold filter drop-shadow-lg">
      <inner-test>
        <p class="mb-2">Radiator & Pipe Encasement Solutions at P&D <font-awesome-icon icon="check" class="ml-2 text-gold"/>
        </p>
        <p class="mb-2">Complete Design Service <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p class="mb-2">Professional Installation <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p>CHAS & ConstructionLine Accredited <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
      </inner-test>
    </div>
  </div>
</template>

<script>
import Splash from "@/components/Splash";
import FirstText from "@/components/FirstText";
import textImage from '../assets/1x/rad-fade.webp'
import Work from "@/components/Work";
import R1 from '../assets/1x/r1.webp'
import R2 from '../assets/1x/r2.webp'
import R3 from '../assets/1x/r3.webp'
import Rad1 from '../assets/1x/rad-1.webp'
import Rad2 from '../assets/1x/rad-2.webp'
import Rad3 from '../assets/1x/rad-3.webp'
import mySlider from "@/components/Slider";
import InnerTest from "@/components/InnerTest";
export default {
  name: "Radiator",
  title: "P&D Radiator Guard & Pipe Boxing",
  meta: 'With expertise in the industry, specialist installers and the ability to scale to your job; P&D are the partner for your encasement challenges',
  components:{InnerTest, mySlider, Work, FirstText, Splash},
  data(){
    return{
      textImage: textImage,
      threeImages: [
          R1, R2, R3
      ],
      sliderImages: [
          Rad1, Rad2, Rad3
      ]
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
