<template>
  <div>
    <splash :img="img" :bg-class="'bg-splash'">
      Column Casing Solutions
    </splash>

    <div class="w-full mt-24">
      <first-text :img="textImage">
        <template #title>Bespoke Encasement Solutions Designed & Installed With Superior Service</template>
        <template #bodyText>
          <p></p>
          <p>Steel and concrete support columns in and around old and new buildings can be a headache for
            designers and can be unsightly. P&amp;D are pleased to be able to offer their services to supply and
            install casing to overcome this problem.</p>
          <p>Column casings offer long life, low maintenance solutions that stand the test of time and can be manufactured from various materials dependent on their application and
            environment.</p>
          <div class="mt-4 relative overflow-hidden" id="mats">
            <p class="mb-0 lg:mb-8"><span class="font-sans-dark mr-2">Wood Column Casings</span> A lightweight, strong solution providing a hard wearing, low maintenance solution.</p>
            <p class="mb-0 lg:mb-8"><span class="font-sans-dark mr-2">Metal Column Casings</span> Unsurpassed in terms of quality, finish and shape.  A non-combustible building element capable of achieving Class O spread of flame.</p>
            <p class="mb-0 lg:mb-8"><span class="font-sans-dark mr-2">GRP Column Casings</span> Available in any BS or RAL colour.  This is the long life, low maintenance solution that's well suited to environments with adverse conditions.</p>
            <p class="mb-0 lg:mb-8"><span class="font-sans-dark mr-2">GRG Column Casings</span> A non-combustible, Class O building solution.  Strong and aesthetically pleasing.</p>
          </div>
        </template>
      </first-text>


      <work :three-images="threeImages">
      </work>

    </div>

    <div class="w-full overflow-hidden hidden md:block">
      <mySlider :three-images="sliderImages">
        <ul>
          <li>Your Choice Of Finishes</li>
          <li class="italic ml-2 text-lg">Any BS or RAL colour</li>
          <li class="italic ml-2 text-lg">Long lasting, low maintenance</li>
          <li class="mt-0 lg:mt-2">Perfect Solution For Covering Columns</li>
          <li class="italic ml-2 text-lg">For every use case and every environment</li>
          <li class="mt-0 lg:mt-2">Huge Scope For Shapes And Sizes</li>
          <li class="italic ml-2 text-lg">Contact us for bespoke design</li>
          <li class="mt-0 lg:mt-2">Interior & Exterior</li>
          <li class="italic ml-2 text-lg">Our teams work nationwide</li>
          <li class="mt-0 lg:mt-2">Non-Combustible Options</li>
          <li class="italic ml-2 text-lg">Class 0 options</li>

          <li class="mt-4 font-sans-dark">Design - Project Management - Installation</li>
        </ul>
         </mySlider>
    </div>

    <div class="w-full flex flex-col justify-center items-center mt-48 bg-primary border-t border-gold filter drop-shadow-lg">
      <inner-test>
        <p class="mb-2">Column Encasement Solutions at P&D <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p class="mb-2">Complete Design Service <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p class="mb-2">Professional Installation <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
        <p>CHAS & ConstructionLine Accredited <font-awesome-icon icon="check" class="ml-2 text-gold"/></p>
      </inner-test>
    </div>
  </div>
</template>

<script>
import Splash from "@/components/Splash";
import FirstText from "@/components/FirstText";
import textImage from '../assets/1x/fade-col.webp'
import Work from "@/components/Work";
import C1 from '../assets/1x/c1.webp'
import C2 from '../assets/1x/c2.webp'
import C3 from '../assets/1x/c3.webp'
import Split from '../assets/1x/three-cols.webp'
import mySlider from "@/components/Slider";
import InnerTest from "@/components/InnerTest";
import anime from "animejs";
export default {
  name: "Columns",
  title: 'Nationwide Column Casing Installation',
  meta: 'Steel and concrete support columns in and around old and new buildings can be a headache for designers and can be unsightly. P&D are pleased to be able to offer their services to supply and install casing to overcome this problem.',
  components:{InnerTest, mySlider, Work, FirstText, Splash},
  data(){
    return{
      textImage: textImage,
      threeImages: [
        C1, C2, C3
      ],
      sliderImages: Split
    }
  },
  mounted() {
    anime({
      targets: '#mats  p',
      keyframes:[
        {
          translateX:[1000,0],
          opacity:[0,0.5],
          duration:500
        },
        {
          duration:500,
          opacity:[0.5,1],

        },
        {
          duration:5000,

        },
        {
          translateX:[0,-1000],
          opacity:[1,0],
          duration:500
        },
      ],
      delay: anime.stagger(6000),
      easing:'linear',
      loop: true
  })
  }
}
</script>

<style scoped>
#mats{
  @apply h-32 w-full
}
#mats p{
  @apply absolute top-0 transform translate-x-196
}
</style>
