<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.04 141.62">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect x="4.76" y="115.62" width="122" height="26"/>
        <line class="cls-1" x1="4.32" y1="115.93" x2="126.99" y2="115.93"/>
        <text class="cls-2" transform="translate(37.06 100.62)">P&amp;D</text>
        <polyline class="cls-1" points="24.88 115.93 24.88 25.4 0.71 49.57 48.87 1.41 89.16 41.71 96.33 48.88"/>
        <line class="cls-1" x1="64.95" y1="1.41" x2="113.34" y2="49.8"/>
        <line class="cls-1" x1="80.18" y1="1.41" x2="126.33" y2="47.56"/>
        <text class="cls-3" transform="translate(14.46 135.12)">WORCESTER</text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoLightBg"
}
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls-2 {
  font-size: 40px;
  @apply font-impact
}

.cls-3 {
  font-size: 17.85px;
  fill: #fff;
  letter-spacing: -0.05em;
  @apply font-sans-dark
}
</style>
