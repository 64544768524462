<template>
  <div class="container mx-auto flex justify-center items-start lg:items-center flex-wrap">
    <div class="w-full bg-primary  text-cream font-sans py-4 mb-8 text-2xl text-center filter drop-shadow mx-6 xl:mx-auto">
      <p><slot name="title"></slot></p>
    </div>
    <div class="w-full md:w-1/2 text-base lg:text-lg xl:text-xl lg:leading-8 flex flex-col items-start h-full flex-grow px-6 xl:px-4 py-8">
      <slot name="bodyText">

      </slot>
    </div>
    <div class="hidden md:block w-1/2">
      <img :src="img" alt="" class="float-right xl:w-3/4">
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstText",
  props:['img']
}
</script>

<style scoped>

</style>
